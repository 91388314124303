import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeIcon from "./../../assets/images/games-icon/home-icon.png";
import CasinoIcon from "./../../assets/images/games-icon/casino-icon.png";
import InplayIcon from "./../../assets/images/games-icon/inplay-icon.png";
import CricketIcon from "./../../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "./../../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "./../../assets/images/games-icon/tennis-icon.png";
import horseracingIcon from "./../../assets/images/games-icon/horseracing-icon.png";
import BasketballIcon from "./../../assets/images/games-icon/basketball-icon.png";
import IceHockeyIcon from "./../../assets/images/games-icon/icehockey-icon.png";
import TableTennisIcon from "./../../assets/images/games-icon/table-tennis-icon.png";
import Badminton from "./../../assets/images/games-icon/badminton-icon.png";
import ArcheryIcon from "./../../assets/images/games-icon/archery-icon.png";
import MotorSportIcon from "./../../assets/images/games-icon/motor-sport-icon.png";
import GreyhoundIcon from "./../../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "./../../assets/images/games-icon/politics-icon.png";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  return (
    <>
      <div className="leftbarSec d-none d-md-block">
        <ul>
          <li className="games_link">
            <h6>Quick Links</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports");
                  }}
                >
                  <img src={HomeIcon} alt="Home" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Inplay", {
                      state: { selectedTab: "1" },
                    });
                  }}
                >
                  <img src={InplayIcon} alt="Inplay" />
                  <span>In-play</span>
                </div>
              </li>
              {/*<li>
                <div
                  onClick={() => {
                    navigate("/casino/worldcasino");
                  }}
                >
                  <img src={CasinoIcon} alt="Casino" />
                  <span>World Casino</span>
                </div>
              </li>*/}
            </ul>
          </li>

          <li className="games_link">
            <h6>Sports</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Cricket", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={CricketIcon} alt="Cricket" />
                  <span>Cricket</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Soccer", {
                      state: { selectedTab: "3" },
                    });
                  }}
                >
                  <img src={SoccerIcon} alt="Soccer" />
                  <span>Soccer</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Tennis", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={TennisIcon} alt="" />
                  <span>Tennis</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Horse%20Racing", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={horseracingIcon} alt="horseracing" />
                  <span>horseracing</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Basketball", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={BasketballIcon} alt="Basketball" />
                  <span>Basketball</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Ice Hockey", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={IceHockeyIcon} alt="Ice Hockey" />
                  <span>Ice Hockey</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Table Tennis", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={TableTennisIcon} alt="Ice Hockey" />
                  <span>Table Tennis</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Badminton", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={Badminton} alt="Badminton" />
                  <span>Badminton</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Archery", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={ArcheryIcon} alt="Archery" />
                  <span>Archery</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Motor Sport", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={MotorSportIcon} alt="Motor Sport" />
                  <span>Motor Sport</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Greyhound%20Racing", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={GreyhoundIcon} alt="Greyhound Racing" />
                  <span>Greyhound Racing</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Politics", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={PoliticsIcon} alt="Politics" />
                  <span>Politics</span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
